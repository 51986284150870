<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">

		<a-row :gutter="24">

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24">
				<h4>List of Topics</h4>
			</a-col>

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-right">
				<a-button id="btn" type="silabu" @click="launchModal" v-if="isCreator">
					<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Topic
				</a-button>
				<h4 style="color: white;" v-if="!isCreator">Silabu</h4>
			</a-col>

		</a-row>
		
		<div v-if="!loadingData == message.length == 0">

			<a-row :gutter="24">

				<a-col class="mb-24" style="height: 100%;" :span="24" :md="8" :lg="8" :xl="8" v-for="tpc in topics" :key="tpc.uuid">
					<a-card class=" px-5 h-100">
							<a-row :gutter="24">
								<a-col :span="24" :md="24" :lg="24" :xl="24">
									<h6>{{ tpc.position }}. {{ tpc.title }}</h6>
								</a-col>
							</a-row>
							
							<a-row :gutter="24">
								<a-col :span="24" :md="24" :lg="24" :xl="24">
									<p>
										{{ tpc.description }}
									</p>
								</a-col>
							</a-row>

							<a-row v-if="isCreator">
								<a-col :span="24" class="text-right mt-2">
									<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditQuiz(tpc)"><a-icon type="form" theme="outlined"/> Edit</a-button>
								
									<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(tpc.uuid)" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
								</a-col>
							</a-row>
						
					</a-card>
				</a-col>

			</a-row>


		</div>


		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>

		<div v-if="!loadingData && message.length > 0">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<label>{{message}}</label>

				</a-col>

			</a-row>
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="topic.showModal" :footer="null">

			<h4 class="text-center">{{  topic.isCreate ? 'Add Topic' : 'Edit Topic'}}</h4>

			<a-form-model
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Title" :colon="false">
					<a-input 
						v-decorator="['name',
						{ rules: [{ required: true, message: 'Please input your tile of the topic!' }] },
						]" placeholder="Title" 
						v-model="topic.title"/>
				</a-form-item>
				
				<a-form-item class="mb-10" label="Position" :colon="false">
					<a-input 
						type="position"
						v-decorator="[
							'position',
							{ rules: [{ required: true, message: 'Please input your topic position!' }] },
						]" 
						placeholder="Position" 
						v-model="topic.position"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Description" :colon="false">
					<a-textarea 
						:rows="4"
						v-decorator="['description',
							{ rules: [{ required: true, message: 'Please input your topic description!' }] },
						]" 
						placeholder="Description" 
						v-model="topic.description" />
				</a-form-item>

				
				<a-form-item id="pricing" class="mb-0 pb-0" label="" :colon="false">
					Completed &nbsp;&nbsp;
					<a-switch class="mt-0 pt-0" v-model="topic.isCompleted"/>
				</a-form-item>

				<a-form-item>
					<a-button type="silabu" id="btn" html-type="submit" class="login-form-button text-white">
						{{ topic.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
				message: '',
                topics: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
				topic: {
					uuid: null,
					title: null,
					position: null,
					description: null,
					isCompleted: false,
					isCreate: true,
					showModal: false,
				}
      		}
		},
		// beforeCreate() {
		// 	// Creates the form and adds to it component's "form" property.
		// 	this.form = this.$form.createForm(this, { name: 'topic_login' });
		// },
		created() {
			this.getTopics();
		},

		methods: {

			onEditQuiz(item) {
				this.topic.uuid = item.uuid;
				this.topic.title = item.title;
				this.topic.position = item.position;
				this.topic.description = item.description;
				this.topic.isCompleted = item.isCompleted;
				this.topic.isCreate = false;
				this.topic.showModal = true;
			},

			launchModal() {
				this.topic.showModal = true;
			},

			async getTopics() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/curriculums/for/${this.classDetails.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						if(response.data.data.length > 0) {
							this.topics = response.data.data;

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						if(this.topic.isCreate) {

							// this.loadingData = true;
							this.topic.uuid = undefined;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/curriculums/for/${this.classDetails.uuid}`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.$AXIOS.post(url, this.topic, config).then(async(response) => {
								
								this.topic.uuid = null,
								this.topic.title = null,
								this.topic.position = null,
								this.topic.description = null,
								this.topic.isCompleted = false,
								this.topic.isCreate = true,
								this.topic.showModal = false,

								this.form.resetFields()

								this.getTopics()

								this.notify('Topic was saved successfully', 'success')

								// this.loadingData = false;

							}).catch(async(error) => {
								
								// this.loadingData = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{
							// this.loadingData = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/curriculums/${this.topic.uuid}`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.topic.uuid = undefined;

							this.$AXIOS.patch(url, this.topic, config).then(async(response) => {
								
								this.topic.uuid = null,
								this.topic.title = null,
								this.topic.position = null,
								this.topic.description = null,
								this.topic.isCompleted = false,
								this.topic.isCreate = true,
								this.topic.showModal = false,

								this.form.resetFields()

								this.getTopics()

								this.notify('Topic was updated successfully', 'success')

								// this.loadingData = false;

							}).catch(async(error) => {
								
								// this.loadingData = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}
				})
			},


			async onDelete(uuid) {

				// e.preventDefault();
				
				// this.loadingData = true;

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/curriculums/${uuid}`;

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

				this.$AXIOS.delete(url, config).then(async(response) => {
					
					this.notify('Topic was deleted successfully', 'success')

					this.getTopics();
					// this.loadingData = false;

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>