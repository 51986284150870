/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import router from './router'
import VueKatex from "vue-katex";
import katex from "katex";
import './plugins/click-away'
import moment from 'moment'
import Axios from './Axios';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './scss/app.scss';


Vue.use(Antd);

window.katex = katex;

Vue.use(VueKatex, {
  globalOptions: {}
});

Vue.prototype.moment = moment

Vue.prototype.$AXIOS = Axios;
// Vue.prototype.$BACKEND_URL = "http://localhost:3000/api/v1";
Vue.prototype.$BACKEND_URL = "https://api.silabu.com/api/v1";



Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
// Vue.component('font-awesome-icon', FontAwesomeIcon)



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')